import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import styled from "styled-components";
import api from "../api";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useNavigate } from "react-router-dom";


const Styles = styled.div`
 background: lavender;
 padding: 20px;

 h1 {
   border-bottom: 1px solid white;
   color: #3d3d3d;
   font-family: sans-serif;
   font-size: 20px;
   font-weight: 600;
   line-height: 24px;
   padding: 10px;
   text-align: center;
 }

 form {
   background: white;
   border: 1px solid #dedede;
   display: flex;
   flex-direction: column;
   justify-content: space-around;
   margin: 0 auto;
   max-width: 500px;
   padding: 30px 50px;
 }

 input {
   border: 1px solid #d9d9d9;
   border-radius: 4px;
   box-sizing: border-box;
   padding: 10px;
   width: 100%;
 }

 label {
   color: #3d3d3d;
   display: block;
   font-family: sans-serif;
   font-size: 14px;
   font-weight: 500;
   margin-bottom: 5px;
 }

 .error {
   color: red;
   font-family: sans-serif;
   font-size: 12px;
   height: 30px;
 }

 .submitButton {
   background-color: #6976d9;
   color: white;
   font-family: sans-serif;
   font-size: 14px;
   margin: 20px 0;
`;

const RegisterView = () => {
    const formSchema = Yup.object().shape({
        email: Yup.string()
            .required('邮箱为必填项')
            .email('邮箱格式不正确'),
        realName: Yup.string()
            .required('真实姓名为必填项'),
        password: Yup.string()
            .min(6, '密码长度至少为6个字符')
            .max(32, '密码长度不能超过32个字符')
            .required('密码为必填项'),
        confirmPwd: Yup.string()
            .required('请再次确认密码')
            .oneOf([Yup.ref('password')], '两次输入的密码不一致'),
        kindergarten: Yup.string()
            .required('必须选择一个单位')
            .notOneOf(['default'], '必须选择一个单位'), // 防止用户提交默认选项
    });

    const formOptions = { resolver: yupResolver(formSchema) }
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState
    const [kindergartens, setKindergartens] = React.useState([]);
    const navigate = useNavigate(); // 获取 navigate 函数
    // 增加一个状态来控制模态框的显示和倒计时
    const [showModal, setShowModal] = useState(false);
    const [countdown, setCountdown] = useState(10);
    const [formData, setFormData] = useState(null);


    const Modal = ({ onClose, countdown, onConfirm, formData }) => {
        const { email, realName, password, kindergarten } = formData || {};
        // 假设 kindergartens 数组在这个组件的作用域内是可访问的
        const kindergartenEntry = kindergartens.find(kg => kg._id === kindergarten);
        const kindergartenName = kindergartenEntry ? `${kindergartenEntry.address} ${kindergartenEntry.name}` : '';

        return (
            <div style={{
                position: 'fixed',
                top: '20%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                backgroundColor: '#f0f0f0', // 更改背景色为浅灰色
                padding: '40px',
                borderRadius: '15px',
                boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                textAlign: 'center',
                width: '80%',
                maxWidth: '500px',
                border: '2px dashed #6976d9', // 添加虚线边框
            }}>
                <p style={{
                    fontSize: '18px',
                    marginBottom: '20px'
                }}>请确认您的注册信息，倒计时 {countdown} 秒。</p>
                <div>
                    <p>幼儿园: {kindergartenName}</p>
                    <p>教师名称: {realName}</p>
                    <p>注册邮箱: {email}</p>
                    <p>密码: {password}</p>
                </div>
                <div>
                    请注册完成后，邮件联系管理员进行账号审核。
                </div>
                <button onClick={onConfirm} disabled={countdown > 0} style={{
                    marginTop: '20px',
                    padding: '10px 20px',
                    fontSize: '16px',
                    borderRadius: '5px',
                    border: 'none',
                    backgroundColor: '#6976d9',
                    color: 'white',
                    cursor: 'pointer',
                    opacity: countdown > 0 ? 0.5 : 1,
                    display: 'block',
                    margin: '20px auto',
                    width: 'fit-content'
                }}>确认注册</button>
                <button onClick={onClose} style={{
                    marginTop: '20px',
                    marginLeft: '10px', // 与确认按钮留出一些间隔
                    padding: '10px 20px',
                    fontSize: '16px',
                    borderRadius: '5px',
                    border: 'none',
                    backgroundColor: 'grey', // 可以选择一个合适的颜色
                    color: 'white',
                    cursor: 'pointer',
                }}>取消</button>
            </div>
        );
    };




    const onConfirmOK = async () => {
        setShowModal(false); // 关闭模态框        
        if (formData) {
            submitRegistration(formData); // 使用存储的表单数据进行注册
        }
    }

    // 实际提交注册信息的函数
    const submitRegistration = async (data) => {
        const { email, realName, password, kindergarten } = data;
        const payload = {
            email,
            realName,
            password,
            kindergarten
        };

        try {
            const res = await api.insertTeacher(payload);
            toast.success('注册成功！', { autoClose: 500 });
            navigate('/login'); // 注册成功后跳转到登录页面
        } catch (err) {
            console.log(err.response.data.error);
            // 根据需要处理错误（例如，显示错误消息）
            toast.error('注册失败：' + err.response.data.error || '未知错误');
        }

    };



    const onSubmit = async (data) => {

        setFormData(data); // 存储表单数据到状态中
        // 先显示确认框
        setShowModal(true);

        // 启动倒计时
        const countdownInterval = setInterval(() => {
            setCountdown((currentCountdown) => {
                // 当倒计时到达0时，停止倒计时而不是重置为10
                if (currentCountdown <= 1) {
                    clearInterval(countdownInterval); // 倒计时结束
                    return 0; // 停止在0
                }
                return currentCountdown - 1;
            });
        }, 1000);
    };



    // 获得所有kindergarten，包括它们的ID
    const getKindergarten = async () => {
        try {
            const res = await api.getKindergartens()
            setKindergartens(res.data.data); // 存储完整的幼儿园对象

        } catch (err) {
            console.log(err.response)
        }
    }

    // 使用 useEffect 钩子在组件加载时调用 getKindergarten
    useEffect(() => {
        getKindergarten();
    }, []); // 空依赖项数组确保只在组件首次渲染时执行

    return (
        <Styles>
            <ToastContainer />
            <form onSubmit={handleSubmit(onSubmit)}>

                {/* 幼儿园选择下拉菜单 */}
                <div className="form-group">
                    <select {...register("kindergarten")} className={`form-control ${errors.kindergarten ? 'is-invalid' : ''}`}>
                        <option value="default">请选择所在单位</option>
                        {kindergartens.map((kg, index) => (
                            <option key={index} value={kg._id}> {/* 使用幼儿园的ID */}
                                {kg.address + kg.name}
                            </option>
                        ))}
                    </select>
                    <div className="invalid-feedback">{errors.kindergarten?.message}</div>
                </div>
                {/* 电子邮件输入框 */}
                <div className="form-group">
                    <input name="email" type="email" {...register('email')} className={`form-control ${errors.email ? 'is-invalid' : ''}`} placeholder="请输入邮箱" />
                    <div className="invalid-feedback">{errors.email?.message}</div>
                </div>
                <div className="form-group">
                    <input name="password" type="password" {...register('password')} className={`form-control ${errors.password ? 'is-invalid' : ''}`} placeholder="请输入密码" />
                    <div className="invalid-feedback">{errors.password?.message}</div>
                </div>
                <div className="form-group">
                    <input name="confirmPwd" type="password" {...register('confirmPwd')} className={`form-control ${errors.confirmPwd ? 'is-invalid' : ''}`} placeholder="请再次输入密码" />
                    <div className="invalid-feedback">{errors.confirmPwd?.message}</div>
                </div>
                {/* 真实姓名输入框 */}
                <div className="form-group">
                    <input name="realName" type="text" {...register('realName')} className={`form-control ${errors.realName ? 'is-invalid' : ''}`} placeholder="真实姓名" />
                    <div className="invalid-feedback">{errors.realName?.message}</div>
                </div>
                <div className="mt-3">
                    <input type="submit" className="submitButton" value="注册" />
                </div>
            </form>
            {showModal && (
                <Modal
                    onClose={() => setShowModal(false)}
                    countdown={countdown}
                    onConfirm={onConfirmOK}
                    formData={formData} // 确保这里传递了最新的formData
                />
            )}

        </Styles>
    )
}

export default RegisterView